.pf-c-page__main {
    height: calc(100vh - 50px);
}

.pf-c-page__main-breadcrumb{
    padding-top: calc(var(--pf-c-page__sidebar-body--PaddingTop) + 1.5px);
    padding-bottom: 10px;
}

.syncrasy-title{
    font-size: 1.3em !important;
}

.margin-top-2{
    margin-top: 2px;
}

.margin-left-auto{
    margin-left: auto;
}


#root > div {
    height: 100%;
}

.pf-l-bullseye{
    height: auto;
}

.pf-c-backdrop > .pf-l-bullseye{
    height: 100%;
}

.syn-disabled > div {
    overflow-x: auto;

    overflow-y: hidden;
    background-color: #ededed;
    height: 35px;
}

.pf-c-form__horizontal-group{
    height: 100% !important;
}

.pf-c-page__header{
    height: 45px !important;
    min-height: 45px !important;
    --pf-c-page__header--BackgroundColor: black;
}

.pf-c-page__header-brand{
    padding-left: 15px !important;
    color: white
}
.pf-c-page__header-brand-link:hover{
    text-decoration: none !important;
}

.pf-c-page__header-brand-link{
    color: white !important;
    line-height: 45px;
    padding-top: 2px !important;
    font-weight: 400 !important;
    font-size: 22px !important;
}

.pf-c-page__header-nav{
    height: 45px !important;
}

.pf-c-nav__horizontal-list .pf-c-nav__link{
    padding-bottom: 5px !important;
    padding-top: 0 !important;
    margin-top: 15px !important;
}

.pf-c-nav__horizontal-list .pf-c-nav__link::after{
    position: relative !important;
}

.pf-c-nav__link.pf-m-current, .pf-c-nav__link.pf-m-current:hover, .pf-c-nav__item.pf-m-current:not(.pf-m-expanded) .pf-c-nav__link{
    background-color: transparent !important;
}

.pf-c-nav__link:hover{
    background-color: transparent !important;
}


.syn-header-error{
    width: 100%;
    padding: 5px;
}
