.wrapper {
    box-sizing: border-box;
}

.table {
    margin: 10px;
    /*width: 100%;*/
    border: 1px solid gray;
}

.table_head{
    max-height: 90px;
    height: 50px;
}

td {
    padding: 10px 20px;
    border: 1px solid gray;
}
.table_body{

}

th {
    padding: 0 20px;
    border: 1px solid gray;
}

.table_col__btn_wrapper{
    display: flex;
    justify-content: space-around;
}